export module Variables {

    export const enum widthsList {

        /*Riskypane*/   
        paneWidth = '316px',
        loginPadding = '60px',

    }
    
    }